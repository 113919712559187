<template>
  <p v-if="contractVergeten" class="block font-bold text-red-500">
    {{ text }}
  </p>
</template>

<script setup>
import { computed, defineProps } from 'vue'
// import useNotification from '@/hooks/useNotification'

const props = defineProps({
  ritDatetime: [Number, String, Object, Date],
  getekend: Boolean,
})

const contractVergeten = computed(() => {
  if (props.getekend || !props.ritDatetime) return false
  const now = Date.now()
  const time = new Date(props.ritDatetime).getTime()
  return now > time
})

const text = 'OPGELET! Contract vergeten!'

// if (contractVergeten.value) {
//   useNotification(text, 'error')
// }
</script>
